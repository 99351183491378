body {
    background-color: white;
}

.orange {
    color: #ffffff!important;
    background: #d68201!important;
    border-color: #d68201!important;
}

#auth {
    height: 100vh;
    overflow-x: hidden;

    #auth-right {
        height: 100%;
        background: url(../../../../public/images/auth-bg.jpg), linear-gradient(90deg, #fcac4d, #fb9800);
    }

    #auth-left {
        padding: 5rem 8rem;

        .auth-title {
            font-size: 3.4rem;
            margin-bottom: 1rem;
        }

        .auth-subtitle {
            font-size: 1.7rem;
            line-height: 2.5rem;
            color: #a8aebb;
        }

        .auth-logo {
            margin-bottom: 3rem;

            img {
                height: 4rem;
            }
        }

        @media screen and (max-width: 767px) {
            padding: 5rem;
        }
    }
}
